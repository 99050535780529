<template>
  <el-dialog :title="dialogTitle" :visible.sync="show" width="940px" :close-on-click-modal="false" @close="handelClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" v-loading="loadingForm">
      <el-row>
        <el-col :span="10">
          <el-form-item label="所属项目部" prop="projectId" required>
            <el-select v-model="ruleForm.projectId" placeholder="请选择" style="width:330px">
              <el-option
                v-for="item in projectList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="姓名" prop="name" required>
            <el-input v-model="ruleForm.name" style="width:330px" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="岗位" prop="post" required>
            <el-input v-model="ruleForm.post" style="width:330px" placeholder="请输入岗位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="体检日期" prop="examinationDate" required>
            <el-date-picker
              style="width:330px"
              value-format="yyyy-MM-dd"
              v-model="ruleForm.examinationDate"
              type="date"
              placeholder="请选择体检日期"
              :clearable="false"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="体检类别" prop="type" required>
            <el-select v-model="ruleForm.type" placeholder="请选择体检类别" style="width:330px" @change="changeType">
              <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="2" v-if="ruleForm.type">
          <el-form-item label="体检结果" prop="result" required>
            <el-select
              v-model="ruleForm.result"
              placeholder="请选择体检结果"
              style="width:330px"
              @change="changeResult"
            >
              <el-option
                v-for="item in resultList"
                :key="item.id"
                :label="item.resultName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-if="(ruleForm.type && ruleForm.type !== 1) || (ruleForm.id && ruleForm.type !== 1)">
          <el-col :span="10">
            <el-form-item label="是否需要复查" prop="review" required>
              <el-select v-model="ruleForm.review" placeholder="请选择是否需要复查" style="width:330px">
                <el-option
                  v-for="item in reviewList"
                  :key="item.id"
                  :label="item.reviewName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2">
            <el-form-item label="复查结果" prop="reviewResult" required>
              <el-select v-model="ruleForm.reviewResult" placeholder="请选择复查结果" style="width:330px">
                <el-option
                  v-for="item in reviewResultList"
                  :key="item.id"
                  :label="item.reviewResultName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
      <el-row
        v-if="
          (ruleForm.result === 3 && ruleForm.type === 2) ||
            (ruleForm.id && ruleForm.result === 3 && ruleForm.type === 2)
        "
      >
        <el-col :span="10">
          <el-form-item label="调岗岗位" prop="jobTransfer" required>
            <el-input v-model="ruleForm.jobTransfer" style="width:330px" placeholder="请输入调岗岗位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="禁忌因素" prop="contraindications" required>
            <el-input v-model="ruleForm.contraindications" style="width:330px" placeholder="请输入禁忌因素"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="handleReset">取消</el-button>
      <el-button @click="handleConfirm" type="primary" :loading="btnLoading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { validateRules } from './validateRules.js';
import { addHealthExaminationApi, edithealthExaminationApi } from 'api/general-user/health/physical-examination';
export default {
  name: 'addDialog',
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: false,
      default: ''
    },
    detailDialogVisible: {
      //显隐
      type: Boolean,
      required: false,
      default: false
    },
    //这些都是父组件传下来的下拉数据（这里没调取接口，感觉太臃肿了，采取的是传值）
    projectList: {
      //所属项目部
      type: Array,
      required: false,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      show: this.detailDialogVisible,
      btnLoading: false,
      loadingForm: false,
      ruleForm: {},
      typeList: [
        {
          id: 1,
          typeName: '岗前'
        },
        {
          id: 2,
          typeName: '在岗'
        },
        {
          id: 3,
          typeName: '离岗'
        }
      ],
      resultList: [
        {
          id: 1,
          resultName: '未见异常'
        },
        {
          id: 2,
          resultName: '疑似职业病'
        },
        {
          id: 3,
          resultName: '职业禁忌症'
        },
        {
          id: 4,
          resultName: '其他结果'
        }
      ],
      reviewList: [
        {
          id: 1,
          reviewName: '是'
        },
        {
          id: 2,
          reviewName: '否'
        }
      ],
      reviewResultList: [
        {
          id: 1,
          reviewResultName: '合格'
        },
        {
          id: 2,
          reviewResultName: '不合格'
        }
      ]
    };
  },
  methods: {
    changeType(type) {
      if (this.ruleForm.id && type === 1) {
        //只要选择了岗前
        this.ruleForm.review = '';
        this.ruleForm.reviewResult = '';
        this.ruleForm.jobTransfer = '';
        this.ruleForm.contraindications = '';
      }
      if (this.ruleForm.id && type === 3) {
        //只要同时选择了离岗和职业禁忌症
        this.ruleForm.jobTransfer = '';
        this.ruleForm.contraindications = '';
      }
    },
    changeResult(val) {
      //体检结果
      if (this.ruleForm.id && val === 3) {
        //代表是修改
        this.ruleForm.jobTransfer = '';
        this.ruleForm.contraindications = '';
      }
    },
    echoFormInit(row) {
      //这里修改的时候要用
      this.ruleForm = row;
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.id) {
            //修改
            this.edithealthExamination();
          } else {
            //新增
            this.addHealthExaminationApi();
          }
        }
      });
    },
    //新增api
    addHealthExaminationApi() {
      this.btnLoading = true;
      addHealthExaminationApi(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:detailDialogVisible', false);
            this.$emit('handel-success', true);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //修改api
    edithealthExamination() {
      this.btnLoading = true;
      edithealthExaminationApi(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('修改成功');
            this.$emit('update:detailDialogVisible', false);
            this.$emit('handel-success', false);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    handelClose() {
      this.commonResetFormFunc();
    },
    handleReset() {
      this.commonResetFormFunc();
    },
    commonResetFormFunc() {
      this.$emit('update:detailDialogVisible', false);
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    }
  },
  watch: {
    detailDialogVisible(val) {
      this.show = val;
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"查看详情","visible":_vm.show,"width":"1067px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.handelClose}},[_c('div',{staticClass:"project-title"},[_c('span',[_vm._v("所属项目部")]),_c('span',{staticClass:"project-name"},[_vm._v(_vm._s(_vm.projectName))])]),_c('el-form',{attrs:{"inline":""}},[_c('el-form-item',{attrs:{"label":"模糊查询"}},[_c('el-input',{staticStyle:{"width":"280px"},attrs:{"placeholder":"输入姓名/岗位搜索"},model:{value:(_vm.listPamers.searchValue),callback:function ($$v) {_vm.$set(_vm.listPamers, "searchValue", $$v)},expression:"listPamers.searchValue"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handelSearchList}},[_vm._v("查询")])],1)],1),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.userListLoding),expression:"userListLoding"}],attrs:{"data":_vm.userRecordList,"stripe":"","border":"","max-height":"560px"}},[_c('el-table-column',{attrs:{"label":"序号","width":"55","fixed":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"姓名","fixed":"","align":"center"}}),_c('el-table-column',{attrs:{"prop":"post","label":"岗位","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"体检类别","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("typeLabel")(row.type)))])]}}])}),_c('el-table-column',{attrs:{"label":"体检结果","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("resultLabel")(row.result)))])]}}])}),_c('el-table-column',{attrs:{"label":"是否需要复查","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("reviewLabel")(row.review)))])]}}])}),_c('el-table-column',{attrs:{"prop":"reviewResult","label":"复查结果","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("reviewResultLabel")(row.reviewResult)))])]}}])}),_c('el-table-column',{attrs:{"label":"调岗岗位","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.jobTransfer || '/'))])]}}])}),_c('el-table-column',{attrs:{"label":"禁忌因素","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.contraindications || '/'))])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.$check('physical-examination_edit'))?_c('span',[_c('span',{staticClass:"action-btn",staticStyle:{"margin-right":"16px"},on:{"click":function($event){return _vm.handleEcho(row.id)}}},[_vm._v("修改")]),_c('span',{staticClass:"danger-btn",on:{"click":function($event){return _vm.handleDelete(row.id)}}},[_vm._v("删除")])]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"total":_vm.total,"current-page":_vm.listPamers.current,"page-size":_vm.listPamers.size,"page-sizes":[10, 20, 30, 50],"layout":"total, sizes, prev, pager, next"},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleReset}},[_vm._v("取消")])],1)],1),_c('add-dialog',{ref:"addDialogRef",attrs:{"detail-dialog-visible":_vm.EchoDialogVisible,"dialog-title":_vm.dialogTitle,"project-list":_vm.projectList},on:{"update:detailDialogVisible":function($event){_vm.EchoDialogVisible=$event},"update:detail-dialog-visible":function($event){_vm.EchoDialogVisible=$event},"update:dialogTitle":function($event){_vm.dialogTitle=$event},"update:dialog-title":function($event){_vm.dialogTitle=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="echart-display">
    <!-- 搜素部分 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="显示层级" v-if="levelDoopDownList.length>1">
          <el-select
            v-model="queryForm.orgId"
            placeholder="请选择显示层级"
            @change="changeOrgIdList"
          >
            <el-option
              v-for="item in levelDoopDownList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select
            style="width:320px"
            v-model="queryForm.projectIdList"
            placeholder="请选择项目部"
            multiple
            collapse-tags
            @change="changeProjectList"
          >
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="体检日期">
          <el-date-picker
          v-model="queryForm.checkTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="getHealthPhotoData()"
          >
         </el-date-picker>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select v-model="queryForm.typeList" placeholder="请选择体检类别" multiple collapse-tags @change="changeTypeList">
            <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.resultList" placeholder="请选择体检结果" multiple collapse-tags @change="changeResultList">
           <el-option v-for="item in resultList" :key="item.id" :label="item.resultName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.reviewList" placeholder="请选择是否需要复查" multiple collapse-tags @change="changeReviewList">
            <el-option v-for="item in reviewList" :key="item.id" :label="item.reviewName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.reviewResultList" placeholder="请选择复查结果" multiple collapse-tags @change="changeReviewResultList">
            <el-option v-for="item in reviewResultList" :key="item.id" :label="item.reviewResultName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { selectList } from '../selectList.js';
import { getDataRang,getDataRangByNature } from 'api/common'
import { getPhotoDataApi} from 'api/general-user/health/physical-examination'
import echartsLine from 'components/EchartsLine';
export default {
  mixins:  [selectList],
  components: {
    echartsLine
  },
  data() {
    return {
       pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      queryForm: {
       // projectIdList: [],
        orgId: 1,//默认是集团公司的或者管理员
      /*   typeList: [],//体检类别
        resultList: [],//体检结果
        reviewList:[],//是否需要复查
        reviewResultList:[],//复查结果
        searchBeginTime:'',
        searchEndTime:'',
        checkTime:[], */
      },
      xData: [], //图表横坐标
      dataList: [], //图表数据
      //层级下拉列表
      levelDoopDownList:[],
      //项目部下拉列表
      projectList:[],
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      isCheckedAllTypeFlag: false, //体检类别(是否全选)
      isCheckedAllResultFlag: false, //体检结果（是否全选）
      isCheckedAllReviewFlag: false, //是否需要复查（是否全选）
      isCheckedAllReviewResultFlag: false, //复查结果（是否全选）
    };
  },
  methods: {
    changeOrgIdList(){
      this.getDataRangByNature();
    },
    changeProjectList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.orgId);
          });
          this.queryForm.projectIdList = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.projectIdList = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.projectIdList = id.filter(item => item !== '');
      }
      this.getHiddenDangerPhoto();
    },
    changeTypeList(id) {
      this.queryForm.resultList = [];
      this.queryForm.reviewList = [];
      this.queryForm.reviewResultList = [];
      if (!this.isCheckedAllTypeFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.typeList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.typeList = arr;
          this.isCheckedAllTypeFlag = true;
        } else {
          this.queryForm.typeList = id;
        }
      } else {
        this.isCheckedAllTypeFlag = false;
        this.queryForm.typeList = id.filter(item => item !== '');
      }
      this.getHealthPhotoData();
    },
    changeResultList(id) {
      this.queryForm.typeList = [];
      this.queryForm.reviewList = [];
      this.queryForm.reviewResultList = [];
      if (!this.isCheckedAllStatusFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.resultList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.resultList = arr;
          this.isCheckedAllResultFlag = true;
        } else {
          this.queryForm.resultList = id;
        }
      } else {
        this.isCheckedAllResultFlag = false;
        this.queryForm.resultList = id.filter(item => item !== '');
      }
      this.getHealthPhotoData();
    },
    changeReviewList(id){
      this.queryForm.typeList = [];
      this.queryForm.resultList = [];
      this.queryForm.reviewResultList = [];
      if (!this.isCheckedAllReviewFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.reviewList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.reviewList = arr;
          this.isCheckedAllReviewFlag = true;
        } else {
          this.queryForm.reviewList = id;
        }
      } else {
        this.isCheckedAllReviewFlag = false;
        this.queryForm.reviewList = id.filter(item => item !== '');
      }
       this.getHealthPhotoData();
    },
    changeReviewResultList(id){
      this.queryForm.typeList = [];
      this.queryForm.resultList = [];
      this.queryForm.reviewList = [];
      if (!this.isCheckedAllReviewResultFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.reviewResultList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.reviewResultList = arr;
          this.isCheckedAllReviewResultFlag = true;
        } else {
          this.queryForm.reviewResultList = id;
        }
      } else {
        this.isCheckedAllReviewResultFlag = false;
        this.queryForm.reviewResultList = id.filter(item => item !== '');
      }
      this.getHealthPhotoData();
    },
    getHealthPhotoData() {
      //图表接口
      let params = cloneDeep(this.queryForm);
      if (params.projectIdList.includes('')) {
        //判断数组中是否有空字符串
        params.projectIdList = params.projectIdList.filter(item => item !== ''); //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.typeList.includes('')) {
        params.typeList = params.typeList.filter(item => item !== '');
      }
      if (params.resultList.includes('')) {
        params.resultList = params.resultList.filter(item => item !== '');
      }
      
      if (params.reviewList.includes('')) {
        params.reviewList = params.reviewList.filter(item => item !== '');
      }
      
      if (params.reviewResultList.includes('')) {
        params.reviewResultList = params.reviewResultList.filter(item => item !== '');
      }
      if( params.checkTime){
        params.searchBeginTime=params.checkTime[0];
        params.searchEndTime=params.checkTime[1];
      } 
      getPhotoDataApi(params).then(res => {
        if (res.code === 200) {
          const { healthExaminationInfoList, nameList, allNum } = res.data;
          this.xData = nameList; //横坐标的值
          if (res.code === 200) {
            if (healthExaminationInfoList.length) {
              //只要选择了其他下拉选择，healthExaminationInfoList字段就有值
              healthExaminationInfoList.forEach((item, index) => {
                item.type = 'bar';
                item.name=item.value;
                item.stack = 'bar';
                item.barWidth = 20;
                item.data = item.projectDataList;
                item.id = index; //这里要加，不是全是空，会报错
              });
              this.dataList = healthExaminationInfoList;
            } else {
              //只选择了所属项目部
              this.dataList = [
                {
                  data: allNum,
                  type: 'bar',
                  barWidth: 20,
                  backgroundColor: '#1890FF',
                  name: '体检人数'
                }
              ];
            }
          }
        }
        this.$nextTick(() => {
          this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
        });
      });
    },
   
    //获取层级
    getDataRang(){
      getDataRang().then(res=>{
        if(res.code===200){
          const {data}=res;
          if(data.length){
            this.levelDoopDownList.push(...data);
          }else{
            this.queryForm.orgId="";//只是项目部的人，传空
          }
          this.getDataRangByNature();
        }
      })
    },
    //获取项目部
    getDataRangByNature() {
      //这个单独接口获取(所属项目部)
      getDataRangByNature({orgId:this.queryForm.orgId}).then(res => {
        if (res.code === 200) {
          this.projectList=[];
          this.queryForm.projectIdList=[]
          let { data } = res;
          if(data.length){
            data.unshift({orgName: '全选', orgId: '' });
            this.projectList.push(...data);
            //这里还要做件事情，把orgId放在一个数组里面
            this.projectList.forEach(item=>{
              if(item.orgId!==''){
                this.queryForm.projectIdList.push(item.orgId);
              }
            });
          }
          //调取图表接口
           this.getHealthPhotoData();
        }
      });
    }
},
  created() {
    this.getDataRang();
  }
};
</script>



export const validateRules = {
  data() {
    var validateProjectId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入姓名'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入岗位'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateExaminationDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择体检日期'));
      }
      callback();
    };
    var validateType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择体检类别'));
      }
      callback();
    };
    var validateResult = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择体检结果'));
      }
      callback();
    };
    var validateReview = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择是否需要复查'));
      }
      callback();
    };
    var validateReviewResult = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择复查结果'));
      }
      callback();
    };
    var validateJobTransfer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入调岗岗位'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateContraindications = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入禁忌因素'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    return {
      rules: {
        projectId: [{ validator: validateProjectId, trigger: 'change' }],
        name: [{ validator: validateName }],
        post: [{ validator: validatePost }],
        type: [{ validator: validateType, trigger: 'change' }],
        examinationDate: [{ validator: validateExaminationDate }],
        result: [{ validator: validateResult, trigger: 'change' }],
        review: [{ validator: validateReview, trigger: 'change' }],
        reviewResult: [{ validator: validateReviewResult, trigger: 'change' }],
        jobTransfer: [{ validator: validateJobTransfer }],
        contraindications: [{ validator: validateContraindications }]
      }
    };
  }
};

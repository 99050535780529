<template>
  <div>
    <el-dialog title="查看详情" :visible.sync="show" width="1067px" :close-on-click-modal="false" @close="handelClose">
      <div class="project-title">
        <span>所属项目部</span>
        <span class="project-name">{{ projectName }}</span>
      </div>
      <el-form inline>
        <el-form-item label="模糊查询">
          <el-input v-model="listPamers.searchValue" placeholder="输入姓名/岗位搜索" style="width:280px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearchList">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div>
        <el-table :data="userRecordList" stripe border v-loading="userListLoding" max-height="560px">
          <el-table-column label="序号" width="55" fixed align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" fixed align="center"> </el-table-column>
          <el-table-column prop="post" label="岗位" align="center" show-overflow-tooltip> </el-table-column>
          <el-table-column label="体检类别" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.type | typeLabel }}</span>
            </template>
          </el-table-column>
          <el-table-column label="体检结果" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.result | resultLabel }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否需要复查" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.review | reviewLabel }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="reviewResult" label="复查结果" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.reviewResult | reviewResultLabel }}</span>
            </template>
          </el-table-column>
          <el-table-column label="调岗岗位" align="center" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span>{{ row.jobTransfer || '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="禁忌因素" align="center" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span>{{ row.contraindications || '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" fixed="right" align="center">
            <template slot-scope="{ row }">
              <span v-if="$check('physical-examination_edit')">
                <span class="action-btn" style="margin-right:16px" @click="handleEcho(row.id)">修改</span>
                <span class="danger-btn" @click="handleDelete(row.id)">删除</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :current-page="listPamers.current"
          :page-size="listPamers.size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="total, sizes, prev, pager, next"
        ></el-pagination>
      </div>
      <div slot="footer">
        <el-button @click="handleReset">取消</el-button>
      </div>
    </el-dialog>
    <!-- 修改引入addDialog回显 -->
    <add-dialog
      :detail-dialog-visible.sync="EchoDialogVisible"
      :dialog-title.sync="dialogTitle"
      :project-list="projectList"
      ref="addDialogRef"
    ></add-dialog>
  </div>
</template>

<script>
import { getDataRangByNature } from 'api/common';
import {
  getOrgHealthExaminationDataApi,
  deleteHealthExaminationApi,
  getListSingelDetailApi
} from 'api/general-user/health/physical-examination';
import addDialog from './addDialog.vue';
export default {
  components: {
    addDialog
  },
  props: {
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    projectId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      show: this.dialogVisible,
      userListLoding: false,
      listPamers: {
        searchValue: '',
        projectId: '',
        size: 10,
        current: 1
      },
      total: 0,
      userRecordList: [],
      projectName: '',
      //回显dialog
      EchoDialogVisible: false,
      dialogTitle: '',
      projectList: []
    };
  },
  methods: {
    //修改
    handleEcho(id) {
      this.dialogTitle = '修改';
      this.$emit('update:dialogVisible', false);
      this.EchoDialogVisible = true;
      this.getDataRangByNature();
      this.getListSingelDetail(id);
    },
    //修改详情Api
    getListSingelDetail(id) {
      getListSingelDetailApi({ id }).then(res => {
          this.$refs.addDialogRef.echoFormInit(res.data);
      });
    },
    handleDelete(id) {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteHealthExamination(id);
      });
    },
    deleteHealthExamination(id) {
      deleteHealthExaminationApi({ id }).then(res => {
          this.$message.success('删除成功');
          this.getOrgHealthExaminationData();
      });
    },
    //查询
    handelSearchList() {
      this.getOrgHealthExaminationData();
    },
    getDataRangByNature() {
      //这个单独接口获取(所属项目部)
      getDataRangByNature().then(res => {
          let { data } = res;
          this.projectList = data;
      });
    },
    //分页
    getOrgHealthExaminationData() {
      this.userListLoding = true;
      getOrgHealthExaminationDataApi(this.listPamers).then(res => {
          this.userListLoding = false;
          const { records, total } = res.data.page;
          this.total = total;
          this.userRecordList = records;
          this.projectName = res.data.projectName;
      });
    },
    handleSizeChange(size) {
      this.listPamers.current = 1;
      this.listPamers.size = size;
      this.getOrgHealthExaminationData();
    },
    handleCurrentChange(current) {
      this.listPamers.current = current;
      this.getOrgHealthExaminationData();
    },
    handelClose() {
      this.commonResetFunc();
    },
    handleReset() {
      this.commonResetFunc();
    },
    commonResetFunc() {
      this.$emit('update:dialogVisible', false);
      this.listPamers = {
        searchValue: '',
        projectId: '',
        size: 10,
        current: 1
      };
    }
  },
  filters: {
    //体检类别
    typeLabel(type) {
      return type === 1 ? '岗前' : type === 2 ? '在岗' : '离岗';
    },
    //体检结果
    resultLabel(result) {
      switch (result) {
        case 1:
          return '未见异常';
        case 2:
          return '疑似职业病';
        case 3:
          return '职业禁忌症';
        default:
          return '其他结果';
      }
    },
    //是否需要复查
    reviewLabel(review) {
      return review === 1 ? '是' : review === 2 ? '否' : '/';
    },
    //复查结果
    reviewResultLabel(reviewResult) {
      return reviewResult === 1 ? '合格' : reviewResult === 2 ? '合格' : '/';
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
      this.listPamers.projectId = this.projectId;
      if (val) {
        this.getOrgHealthExaminationData();
      }
    }
  }
};
</script>

<style scoped>
.project-title {
  color: #303133;
  margin-bottom: 24px;
}
.project-name {
  margin-left: 16px;
}
</style>

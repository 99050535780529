import request from '@/utils/request.js';
/**
 * 分机构查询职业健康体检记录分页数据
 * @returns {Promise}
 */
export function getHealthExaminationListApi(data) {
  return request({
    url: '/traffic-construction/healthExamination/list',
    method: 'post',
    data
  });
}

/**
 * 添加职业健康体检记录
 * @returns {Promise}
 */
export function addHealthExaminationApi(data) {
  return request({
    url: '/traffic-construction/healthExamination/add',
    method: 'post',
    data
  });
}

/**
 * 某一机构职业健康体检记录详细数据列表
 * @returns {Promise}
 */
export function getOrgHealthExaminationDataApi(params) {
  return request({
    url: '/traffic-construction/healthExamination/getOrgHealthExaminationData',
    method: 'get',
    params
  });
}

/**
 * 删除职业健康体检记录
 * @returns {Promise}
 */
export function deleteHealthExaminationApi(data) {
  return request({
    url: '/traffic-construction/healthExamination/delete',
    method: 'post',
    data
  });
}

/**
 * 职业健康体检记录详细数据
 * @returns {Promise}
 */
export function getListSingelDetailApi(params) {
  return request({
    url: '/traffic-construction/healthExamination/getDetail',
    method: 'get',
    params
  });
}

/**
 * 修改职业健康体检记录
 * @returns {Promise}
 */
export function edithealthExaminationApi(data) {
  return request({
    url: '/traffic-construction/healthExamination/edit',
    method: 'post',
    data
  });
}

/**
 * 分机构导出职业健康体检记录统计数据
 * @returns {Promise}
 */
export function exportHealthExaminationApi(data) {
  return request({
    url: '/traffic-construction/healthExamination/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

/**
 * 分机构导出职业健康体检记录统计数据
 * @returns {Promise}
 */
export function getPhotoDataApi(data) {
  return request({
    url: '/traffic-construction/healthExamination/getPhotoData',
    method: 'post',
    data
  });
}

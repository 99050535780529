export const selectList = {
  data() {
    return {
      typeList: [
        {
          id: '',
          typeName: '全选'
        },
        {
          id: 1,
          typeName: '岗前'
        },
        {
          id: 2,
          typeName: '在岗'
        },
        {
          id: 3,
          typeName: '离岗'
        }
      ],
      resultList: [
        {
          id: '',
          resultName: '全选'
        },
        {
          id: 1,
          resultName: '未见异常'
        },
        {
          id: 2,
          resultName: '疑似职业病'
        },
        {
          id: 3,
          resultName: '职业禁忌症'
        },
        {
          id: 4,
          resultName: '其他结果'
        }
      ],
      reviewList: [
        {
          id: '',
          reviewName: '全选'
        },
        {
          id: 1,
          reviewName: '是'
        },
        {
          id: 2,
          reviewName: '否'
        }
      ],
      reviewResultList: [
        {
          id: '',
          reviewResultName: '全选'
        },
        {
          id: 1,
          reviewResultName: '合格'
        },
        {
          id: 2,
          reviewResultName: '不合格'
        }
      ]
    };
  }
};

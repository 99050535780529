<template>
  <div>
    <!-- 列表搜索 -->
    <div>
      <el-form inline>
        <el-form-item label="所属项目部">
          <el-select
            v-model="queryForm.projectIdList"
            placeholder="请选择所属项目部"
            multiple
            collapse-tags
            style="width:320px"
          >
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <operate-button>
      <template #default>
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="handleAdd"
          v-if="$check('physical-examination_add')"
          >新增</el-button
        >
        <el-button
          v-if="$check('physical-examination_export')"
          class="export-btn"
          :disabled="btnDisabeld"
          @click="handleExport"
          v-loading.fullscreen="exportLoading"
          element-loading-text="拼命导出中"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          >导出</el-button
        >
      </template>
    </operate-button>
    <!-- 列表 -->
    <div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tabelLoding"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed align="center"> </el-table-column>
        <el-table-column label="序号" width="55" fixed align="center" type="index"> </el-table-column>
        <el-table-column prop="projectName" label="所属项目部" fixed align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="totalNumber" label="体检人数" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="naNumber" label="未见异常" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="soNumber" label="疑似职业病" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="ocNumber" label="职业禁忌症" align="center" sortable> </el-table-column>
        <el-table-column prop="otherNumber" label="其他结果" align="center" show-overflow-tooltip sortable>
        </el-table-column>

        <el-table-column prop="address" label="操作" fixed="right" align="center">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="handleView(row.projectId)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page.sync="queryForm.current"
        :page-size.sync="queryForm.size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 查看详情dialog -->
    <list-display-dialog :dialog-visible.sync="dialogVisible" :project-id="projectId"></list-display-dialog>
    <!-- 新增dialog -->
    <add-dialog
      :detail-dialog-visible.sync="detailDialogVisible"
      :dialog-title.sync="dialogTitle"
      :project-list="projectList"
      @handel-success="handelSuccess"
    ></add-dialog>
  </div>
</template>

<script>
import { exportFun } from '@/utils/export.js';
import { getDataRangByNature } from 'api/common';
import { getHealthExaminationListApi, exportHealthExaminationApi } from 'api/general-user/health/physical-examination';
import operateButton from 'views/pages/general-user/security-pages/prevention-control/_components/operateButton';
import listDisplayDialog from './listDisplayDialog';
import addDialog from './addDialog';
export default {
  components: {
    operateButton,
    listDisplayDialog,
    addDialog
  },
  data() {
    return {
      queryForm: {
        projectIdList: [],
        size: 10,
        current: 1
      },
      projectList: [],
      btnDisabeld: true,
      exportLoading: false,

      detailDialogVisible: false, //新增
      dialogVisible: false, //查看详情
      //新增dialog
      dialogTitle: '',
      //查看详情dialog
      tableData: [],
      total: 0,
      tabelLoding: false,
      projectId: '',
      //导出需要的projectIdList
      projectIdList: []
    };
  },
  methods: {
    handleView(projectId) {
      this.dialogVisible = true;
      this.projectId = projectId;
    },
    handleAdd() {
      this.dialogTitle = '新增';
      this.detailDialogVisible = true;
    },
    //导出
    handleExport() {
      this.exportLoading = true;
      exportHealthExaminationApi({
        projectIdList: this.projectIdList
      })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '导出数据成功',
              onClose: () => {
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    handleSelectionChange(row) {
      this.projectIdList = [];
      row.forEach(item => {
        this.projectIdList.push(item.projectId);
      });
      this.btnDisabeld = !Boolean(this.projectIdList.length);
    },
    getDataRangByNature() {
      //这个单独接口获取(所属项目部)
      getDataRangByNature().then(res => {
          let { data } = res;
          this.projectList = data;
      });
    },
    //分页列表
    getHealthExaminationList() {
      this.tabelLoding = true;
      getHealthExaminationListApi(this.queryForm).then(res => {
          this.tabelLoding = false;
          if (res.data) {
            const { records, total } = res.data;
            this.tableData = records;
            this.total = total;
          }
      });
    },
    handelSuccess(boolean) {
      //true->新增，false编辑
      //刷新列表
      if (boolean) {
        this.queryForm.current = 1;
      }
      this.getHealthExaminationList();
    },
    handelSearch() {
      this.queryForm.current = 1;
      this.queryForm.size = 10;
      this.getHealthExaminationList();
    },
    handelReset() {
      this.queryForm = {};
      this.$refs.multipleTable.clearSort();
      this.getHealthExaminationList();
    },
    handleSizeChange(size) {
      this.queryForm.current = 1;
      this.queryForm.size = size;
      this.getHealthExaminationList();
    },
    handleCurrentChange(current) {
      this.queryForm.current = current;
      this.getHealthExaminationList();
    }
  },
  created() {
    this.getHealthExaminationList();
    this.getDataRangByNature();
  }
};
</script>
